import "./login.scss";

import { Grid, Icon, Segment } from "semantic-ui-react";

import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";

export const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>De Beers Group Sight Room Reservation Portal - Privacy Policy</title>
            </Helmet>
            <Grid
                textAlign="center"
                verticalAlign="middle"
                className="terms-conditions">
                <Grid.Column className="terms-holder">
                    <Segment className="terms-container">
                        <Grid textAlign="left">
                            <Grid.Row>
                                <Grid.Column>
                                    <Icon
                                        name="arrow left"
                                        className="help-back-navigation-icon"
                                    />
                                    <span className="back-navigation-button-text">
                                        <Link to="/" className="back-navigation-button-text-color">
                                            Back
                                        </Link>
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="footer-header">Privacy Policy</div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <p className="p-text-colour">
                                        We may collect personal information, including but not limited to, your full name, 
                                        email address and company name for the purposes of registering you as a user on 
                                        the Sight Room Reservation Portal 
                                        (such information referred to herein after as "User Information").
                                    </p>
                                    <p className="p-text-colour">
                                        Our information collection and use policies with respect to such User Information is set 
                                        out in our Privacy Policy and Cookie Policy. For further information on how we handle 
                                        your personal data, you can access the De Beers Group Privacy Notice which is available 
                                        <a href='https://www.debeersgroup.com/site-services/privacy-policy' 
                                        className="p-link-text-colour"> <u>here</u> </a>
                                    </p>
                                    <p className="p-text-colour">
                                        You acknowledge and agree that you are solely responsible for the accuracy 
                                        and content of the User Information.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    );
};
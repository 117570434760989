import "./login.scss";
import "./textWrappingStyling.scss";

import { Grid, Icon, Segment } from "semantic-ui-react";

import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";

export const TermsConditions = () => {
  const today = new Date();

  return (
    <>
      <Helmet>
        <title>De Beers Group Sight Room Reservation Portal - Terms and Conditions</title>
      </Helmet>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        className="terms-conditions"
      >
        <Grid.Column className="terms-holder">
          <Segment className="terms-container">
            <Grid textAlign="left">
              <Grid.Row>
                <Grid.Column>
                  <Icon
                    name="arrow left"
                    className="help-back-navigation-icon"
                  />
                  <span className="back-navigation-button-text">
                    <Link
                      to="/reservations"
                      className="back-navigation-button-text-color"
                    >
                      Back
                    </Link>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div className="footer-header">Terms and Conditions</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    TERMS AND CONDITIONS APPLICABLE TO THE USE OF THE DE BEERS
                    SIGHTHOLDER SIGHT ROOM RESERVATION PORTAL (THE “TERMS AND
                    CONDITIONS”)
                  </p>
                  <p>
                    Any use by you, whether as a guest or a registered user, of
                    the website <Link to="/" className="p-link-text-colour"><u>{window.location.origin}</u></Link>{" "}
                    (the “Site”), which is operated by De Beers Global
                    Sightholder Services (Proprietary) Limited (“GSS”) is
                    conditional upon your acceptance of these Terms and
                    Conditions. Please read these Terms and Conditions carefully
                    before you start using this Site. You may not proceed to or
                    make use of other parts of this Site without first reading
                    to confirm your acceptance of these Terms and Conditions. We
                    reserve the right to amend these Terms and Conditions from
                    time to time without notice and at our discretion, however,
                    we will notify users of any significant change. It is your
                    responsibility to review this page for updates to these
                    Terms and Conditions, which shall come into effect once
                    posted. You agree that your continued use of the Site will
                    be deemed acceptance of these Terms and Conditions,
                    including our Privacy Policy (please see paragraph 9 below
                    for additional information).
                  </p>
                  <p>
                    These Terms refer to the following additional terms, which
                    also apply to your use of the site:
                  </p>
                  <p>
                    Our Privacy Policy and Cookie Policy, which sets out the
                    terms on which we process any personal data we collect from
                    you or that you provide to us. By using the Site, you
                    consent to such processing and you warrant that all data
                    provided by you is accurate.
                  </p>
                  <p>
                    Our Acceptable Use Policy, which sets out the permitted and
                    prohibited uses of the Site. When using the Site, you must
                    comply with this policy.
                  </p>
                  <p>
                    IF YOU DO NOT ACCEPT THESE TERMS AND CONDITIONS PLEASE LEAVE
                    THE SITE NOW.
                  </p>
                  <p>
                    All references to ‘our’, ‘us’, ‘we’ or ‘company’ within
                    these Terms and Conditions are deemed to refer to GSS. “De
                    Beers Group” means any company directly or indirectly
                    controlled by De Beers Plc (44 Esplanade, St Helier, Jersey
                    JE4 9WG).
                  </p>
                  <p>
                    All references to ‘you’, ‘your’, or ‘the user’ within these
                    Terms and Conditions are deemed to refer to the user of the
                    Site.
                  </p>
                  <p>
                    The following terms are defined in the 2021-2023 Supply
                    Agreement Definitions (and or any successor to the 2021-2023
                    Supply Documentation in force from time to time) accessible
                    in the “Resources” section of “Document” of the Site: “Best
                    Practice Principles”, “De Beers Plc”, “De Beers UK Limited”,
                    “Planned Supply Planning Services, “Producer Partnership
                    DTC’s”, “Integrated Retailer Service Fee”, “Selling Period”,
                    “Sight”, “Sightholder”, “2021-2023 Supply Agreement”,
                    “2021-2023 Supply Documentation”, “2021-2023- Supply
                    Period”, “2021-2023 Supply Terms and Conditions”, and
                    “Supply Planning Fee”.
                  </p>

                  <p>
                    <ol>
                      <li>&emsp;Copying and Copyright Notice</li>
                      <ol>
                        <li>
                          Reproduction by the user of the whole of the Site is
                          strictly prohibited. Users are, however, subject to
                          the remaining provisions of these Terms and
                          Conditions, permitted to download information from the
                          Site (unless expressly stated otherwise) as follows:
                          <ol>
                            <br></br>
                            <li>
                              information from the Site is used for the user's
                              business use only;
                            </li>
                            <br></br>
                            <li>
                              no information from the Site is to be modified in
                              any way;
                            </li>
                            <br></br>
                            <li>
                              no information from the Site is to be used in
                              breach of the EU Data Protection Directive
                              95/46/EC as implemented in the appropriate local
                              territories of the European Union until 25 May
                              2018 and the General Data Protection Regulation
                              (EU) 2016/679 on and from 25 May 2018 (or any
                              amendments or updates thereto);
                            </li>
                            <br></br>
                            <li>
                              the contents of the Site may be saved to disk or
                              otherwise stored in a private electronic retrieval
                              system or may be printed provided that: (a) such
                              use is for the user's own business use only; (b)
                              such contents are deleted from any disk or private
                              electronic retrieval system after 30 days from
                              when such content was saved or stored; and (c) no
                              attempt is made by you or anyone acting on your
                              behalf to replicate, resell, hire or to otherwise
                              commercially benefit from any information
                              contained in the Site; and
                            </li>
                            <br></br>
                            <li>
                              the copyright notice: © De Beers Global
                              Sightholder Sales (Pty) Limited 2021 must appear
                              in all reproductions and/or copies.
                            </li>
                          </ol>
                        </li>
                        <li>
                          In accordance with paragraph 13, we reserve the right
                          to limit or refuse access to the Site or any portion
                          of the Site to anyone who fails to comply with or is
                          suspected to not be in compliance with the provisions
                          of paragraph 1.1.
                        </li>
                      </ol>
                      <br></br>
                      <li>&emsp;The De Beers Sight Room Reservation Portal</li>
                      <ol>
                        <li>
                          The De Beers Sight Room Reservation Portal shall be
                          accessed and used by Sightholders and their
                          representatives only.
                        </li>
                        <br></br>
                        <p>Sign-in and Passwords</p>
                        <li>
                          To gain access to the De Beers Sight Room Reservation
                          Portal you will be required to log-in. You will be
                          allocated a username and will be required to create a
                          unique password.
                        </li>
                        <li>
                          If it deems it necessary for security reasons, GSS can
                          enforce a password change at any time, but will warn a
                          user in advance should it intend to do so.
                        </li>
                        <li>
                          Sign-in details and passwords should be treated as
                          confidential for the duration of time that you are
                          entitled to access the Site and any services available
                          on it, including the De Beers Sight Room Reservation
                          Portal. Any sign-in details and passwords provided to
                          you should be kept safe and you should not disclose it
                          to anyone. Usage of an individual sign-in name by
                          multiple users or by someone other than the authorised
                          user is a serious breach of these Terms and Conditions
                          and could lead to GSS suspending or removing access to
                          the Site for a period or indefinitely at GSS’s
                          discretion depending on the severity of the breach.
                        </li>
                        <li>
                          You shall inform GSS immediately of any known or
                          perceived breach of this provision and you shall be
                          liable for any loss or damage that may result from
                          having disclosed or failing to keep secure your
                          sign-in details and/or password.
                        </li>
                      </ol>

                      <li>&emsp;Rights</li>
                      <p>
                        All rights in all material and content (including, but
                        not limited to any lists, text, data, images, web pages,
                        sound, software (including, code, interface and website
                        structure) and video, and the look and feel, design and
                        compilation thereof) in the Information Services and on
                        the Site are owned by us or our licensors. You agree
                        that you are permitted to use this material and/or
                        content solely as set out in these Terms and Conditions
                        or as otherwise expressly authorised in writing by us or
                        our licensors, and that you may not otherwise copy,
                        reproduce, transmit, publicly perform, distribute,
                        commercially exploit, adapt, translate, modify, bundle,
                        merge, share or make available to any person, or create
                        derivative works of such material or content.
                      </p>

                      <li>&emsp;Intellectual Property</li>
                      <p>
                        Other than in respect of any user information uploaded
                        onto the Site, we are the owner and/or authorised
                        licence holder of all trademarks, design rights,
                        patents, copyrights, database rights and all other
                        intellectual property appearing on or contained within
                        the Site, unless otherwise expressly indicated. Except
                        as provided in these Terms and Conditions, use of or
                        access to the Site does not grant you any right, title,
                        interest or licence to any such intellectual property
                        you may access on the Site (including for purposes of
                        clarity, any intellectual property in User Information
                        (defined below) uploaded onto the Site). Except as
                        provided in these Terms and Conditions, any use or
                        reproduction of the intellectual property (including for
                        purposes of clarity, any intellectual property in user
                        information uploaded onto the Site) is strictly
                        prohibited.
                      </p>

                      <li>&emsp;Copyright General</li>
                      <p>
                        All rights, including copyright and database rights, in
                        this Site (including without limitation the Information
                        Services) are and shall remain vested in GSS. All
                        software belongs to GSS or our third-party licensors.
                        All rights are reserved.
                      </p>

                      <li>&emsp;No Warranties</li>
                      <ol>
                        <li>
                          This Site and any information provided on the Site is
                          provided "as is," and your use thereof is at your own
                          risk.
                        </li>
                        <li>
                          We, our officers, directors, employees, agents and
                          assigns, disclaim, to the fullest extent permitted by
                          law, all express and implied warranties, including,
                          without limitation, any warranties of satisfactory
                          quality, fitness for a particular purpose and
                          non-infringement.
                        </li>
                        <li>
                          We do not warrant that the Site will be free from
                          viruses, available at any time or that the contents on
                          the Site will be accurate. Although we take reasonable
                          steps to secure the Site, you acknowledge that the
                          internet is not a completely secure medium and we make
                          no warranties, express or implied, that any
                          information or materials you post on or transmit
                          through the Site will be safe from unauthorized access
                          or use.
                        </li>
                        <li>
                          The information provided on this Site is for general
                          information purposes only and its accuracy is not
                          guaranteed by GSS. Whilst GSS endeavours to ensure
                          that the content contained in the Information Services
                          is correct as at the date of publication, no warranty,
                          express or implied, is given as to the accuracy of the
                          information.
                        </li>
                        <li>
                          Your sole remedy for a breach of any warranty under
                          these Terms and Conditions shall be limited to a
                          breach of these Terms and Conditions and to
                          discontinue using the Site.
                        </li>
                      </ol>
                      <br></br>
                      <li>&emsp;Your Responsibilities</li>
                      <ol>
                        <li>
                          You should always verify the information set out in
                          this Site with independent authorities before acting
                          or relying on it as such information is for general
                          information purposes only and its accuracy is not
                          guaranteed by GSS.
                        </li>
                        <li>
                          It is your responsibility to use virus checking
                          software on any material downloaded from this Site and
                          to ensure the compatibility of such software with your
                          equipment.
                        </li>
                        <li>
                          You warrant that any information that you provide for
                          use in the Directory and/or information you upload to
                          the Site is owned by you, and that you have the
                          necessary permission to upload and/or disseminate that
                          information.
                        </li>
                        <li>
                          Any information relating to third parties that may be
                          accessed by you on the Site is confidential
                          information. You undertake to keep such information
                          confidential and only make use of such information for
                          the purposes of receiving the Services. You indemnify
                          us in accordance with paragraph 8.5 against any claims
                          that such third-party information has been
                          commercially exploited by you.
                        </li>
                      </ol>
                      <br></br>
                      <li>&emsp;No Liability</li>
                      <ol>
                        <li>
                          The material displayed on the Site is for information
                          purposes only and is provided without any guarantees,
                          conditions or warranties as to its accuracy. If you
                          enter into any third-party transaction as a result of
                          any information sourced from this Site, GSS shall not
                          be liable for any use made of that information or any
                          resultant transactions, nor shall GSS be responsible
                          for or involved in that transaction in any way.
                        </li>
                        <li>
                          To the fullest extent permitted by law we, our
                          officers, directors, employees, agents and assigns,
                          hereby expressly exclude: (i) all conditions,
                          warranties and other terms which might otherwise be
                          implied by statute, common law or the law of equity;
                          (ii) all liability for any loss, cost or damage
                          (direct, indirect, consequential, or otherwise)
                          suffered by you as a result of your use of the Site or
                          from any computer virus transmitted through the Site,
                          or other sites accessed from this Site, whether such
                          loss, cost or damage arises from our negligence or
                          otherwise and even if we are expressly informed of the
                          possibility of such loss or damage; (iii) any
                          responsibility or liability for any error or omission
                          in respect of any information on the Site; (iv) any
                          responsibility or liability for any changes to such
                          information following the date of publication of the
                          Directory; and (v) any responsibility or liability for
                          the availability of any websites, details of which are
                          contained in the Directory.
                        </li>
                        <li>
                          In no event shall our total liability to you for all
                          damages, costs, losses and causes of action in the
                          aggregate (whether in contract, tort, including but
                          not limited to negligence, or otherwise) arising from
                          the Terms and Conditions, including our Privacy
                          Policy, or use of the Site exceed, in the aggregate,
                          £1,000 (GBP).
                        </li>
                        <li>
                          No representation or warranty (express or implied) is
                          given as to (i) the accuracy, reasonableness,
                          reliability or completeness of any information
                          provided in connection with the matters contained or
                          referred to in the Site; or (ii) the achievement or
                          likelihood of success or results of any marketing or
                          business initiative or otherwise; or (iii) the
                          reasonableness of any prospects, projections, targets,
                          estimates or forecasts in connection with any
                          marketing or business initiative or otherwise.
                        </li>
                        <li>
                          You shall indemnify, and keep indemnified, GSS and all
                          members of the De Beers Group against any loss, damage
                          or cost incurred by any De Beers Group Company arising
                          out of your use of the Site, including information
                          obtained from linked sites, your submission or
                          transmission of information or material on or through
                          the Site or breach by you of these Terms and
                          Conditions or any laws, regulations and rules, and/or
                          the provision of the Services save where directly
                          attributable to the fraud or wilful default of GSS or
                          relevant member of the De Beers Group. You shall also
                          indemnify the De Beers Group against any claims that
                          information or material which you have submitted to us
                          is in violation of any law or in breach of any third
                          party rights (including, but not limited to, claims in
                          respect of defamation, invasion of privacy, breach of
                          confidence, infringement of copyright or infringement
                          of any other intellectual property right), We reserve
                          the right to exclusively defend and control any claims
                          arising from the above and any such indemnification
                          matters and that you will fully cooperate with us in
                          any such defences.
                        </li>
                        <li>
                          Nothing in these Terms and Conditions excludes or
                          shall limit either party's liability for death or
                          personal injury caused by negligence, liability for
                          fraud or fraudulent misrepresentation, or any other
                          liability which may not otherwise be excluded or
                          limited under applicable law.
                        </li>
                        <li>
                          Certain countries either restrict or prohibit the use
                          of cryptographic products/techniques. For the
                          avoidance of doubt, GSS shall have no responsibility
                          for your use of any cryptology products/techniques
                          available on or in conjunction with this Site in any
                          jurisdiction in which you access the Site. It is your
                          responsibility to ensure compliance with any relevant
                          legislation relating to the use of cryptographic
                          products/techniques. You agree to be compliant with
                          any and all laws relating to cryptology prior to
                          making use of any such technology on the Site, and you
                          shall indemnify GSS against any liability arising as a
                          result of your non-compliant use of the same.
                        </li>
                      </ol>
                      <br></br>
                      <li>&emsp;Privacy Policy and Cookie Policy</li>
                      <p>
                        We may collect personal information, including but not
                        limited to, your full name, email address and company
                        name for the purposes of registering you as a new user
                        on the Sight Room Reservation Portal (such information
                        referred to herein after as "User Information").
                      </p>

                      <p>
                        Our information collection and use policies with respect
                        to such User Information is set out in our Privacy
                        Policy and Cookie Policy. For further information on how
                        we handle your personal data, you can access the De
                        Beers Group Privacy Notice which is available{" "}
                        <a href="https://www.debeersgroup.com/site-services/privacy-policy" className="p-link-text-colour">
                          <u>here</u>
                        </a>
                        .
                      </p>

                      <p>
                        You acknowledge and agree that you are solely
                        responsible for the accuracy and content of the User
                        Information.
                      </p>

                      <p>Cookie List</p>

                      <p>
                        A cookie is a small piece of data (text file) that a
                        website – when visited by a user – asks your browser to
                        store on your device in order to remember information
                        about you, such as your language preference or login
                        information. Those cookies are set by us and called
                        first-party cookies. We also use third-party cookies –
                        which are cookies from a domain different than the
                        domain of the website you are visiting – for our
                        advertising and marketing efforts. More specifically, we
                        use cookies and other tracking technologies for the
                        following purposes:
                      </p>
                      <p>Necessary Cookies</p>
                      <p>
                        These cookies are essential for the operation of the
                        website.
                      </p>
                      <p>Functional Cookies</p>
                      <p>
                        These cookies allow us to perform non-essential
                        functions such as personalised greetings and to
                        understand how users interact with our website. We use
                        third party cookies to help us do this.
                      </p>
                      <p>Performance Cookies</p>
                      <p>
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site.
                      </p>
                      <p>
                        All information these cookies collect is aggregated and
                        therefore anonymous. If you do not allow these cookies
                        we will not know when you have visited our site, and
                        will not be able to monitor its performance.
                      </p>
                      <p>
                        <Link to="/cookies" className="p-link-text-colour">
                          <u>Our Use of Cookies</u>
                        </Link>
                      </p>

                      <li>&emsp;Viruses, Hacking and Other Offences</li>
                      <ol>
                        <li>
                          You must not misuse the Site by knowingly introducing
                          viruses, trojans, worms, logic bombs or other material
                          which is malicious or technologically harmful. You
                          must not attempt to gain unauthorised access to the
                          Site, the server on which the Site is stored or any
                          server, computer or database connected to the Site.
                          You must not attack the Site via a denial-of-service
                          attack or a distributed denial-of service attack.
                        </li>
                        <li>
                          By breaching this provision, you would commit a
                          criminal offence under the Computer Misuse Act 1990.
                          We will report any such breach to the relevant law
                          enforcement authorities and we will co-operate with
                          those authorities by disclosing your identity to them.
                          In the event of such a breach, your right to use the
                          Site will cease immediately.
                        </li>
                      </ol>
                      <br></br>
                      <li>&emsp;Linking to the Site</li>
                      <p>
                        You are not permitted to establish a link to the Site.
                      </p>
                      <li>&emsp;Restriction, Suspension and Termination</li>
                      <p>
                        Access to the Site is permitted on a temporary basis,
                        and we reserve the right to withdraw or amend the Site
                        without notice. We may restrict, suspend or terminate
                        your access to the Site and/or your ability to avail of
                        any of the services on the Site, including interactive
                        services, at any time. Any such restriction, suspension
                        or termination will be without prejudice to any rights
                        which we may have against you in respect of any breach
                        of these Terms and Conditions by you. We may also
                        change, modify, substitute or remove without notice any
                        information on the Site from time to time, remove the
                        Site as a whole or any sections or features of the Site
                        at any time. Please note that we have the ability to
                        trace your IP address and if necessary, contact your ISP
                        in the event of a suspected breach of these Terms and
                        Conditions.
                      </p>
                      <li>&emsp;Miscellaneous</li>
                      <br></br>
                      <li>
                        &emsp;These Terms and Conditions, including our Privacy
                        Policy constitute the entire agreement between you and
                        us in relation to its subject matter and supersedes any
                        and all prior promises, representations, agreements,
                        statements and understandings (whether written or oral)
                        whatsoever between us.
                      </li>
                      <ol>
                        <li>
                          The parties agree and acknowledge that they do not
                          rely on, and shall have no remedy in respect of, any
                          statement, warranty or understanding of any person
                          other than as expressly set out in these Terms and
                          Conditions.
                        </li>
                        <li>
                          To the extent that software is available through the
                          Site, such software may be subject to a licence
                          agreement that is distributed or included with such
                          software, and you agree to comply with the terms and
                          conditions of any such software licence agreements
                          that we may communicate to you from time to time.
                        </li>
                        <li>
                          The failure by us to exercise or enforce any right or
                          provision of the Terms and Conditions shall not
                          constitute a waiver of such right or provision.
                        </li>
                        <li>
                          The parties intend each provision of these Terms and
                          Conditions to be severable and distinct from the
                          others. If any provision of these Terms and Conditions
                          is held to be illegal, invalid or unenforceable, in
                          whole or in part, the parties intend that the
                          legality, validity and enforceability of the remainder
                          of these Terms and Conditions shall not be affected.
                        </li>
                        <li>
                          All disclaimers, indemnities and exclusions in these
                          Terms and Conditions shall survive termination.
                        </li>
                        <li>
                          We may without your prior permission cede, assign or
                          otherwise transfer our rights and obligations in terms
                          of these Terms and Conditions to third parties.
                        </li>
                      </ol>
                      <br></br>
                      <li>&emsp;Law and Jurisdiction</li>
                      <p>
                        These Terms and Conditions, including the Privacy
                        Policy, and any matter relating to this Site, shall be
                        governed by and construed in accordance with English
                        law. The English Courts will have exclusive jurisdiction
                        over any claim arising from, or related to, a visit to
                        the Site, although we retain the right to bring
                        proceedings against you for breach of these Terms and
                        Conditions in your country of residence or any other
                        relevant country.
                      </p>
                      <li>&emsp;Translation (Prevail Paragraph)</li>
                      <p>
                        In the event there is a conflict between this English
                        version of the Terms and Conditions and a translated
                        version, this English version shall prevail.
                      </p>
                      <li>
                        &emsp;Contact Information De Beers Global Sightholder
                        Sales (Pty) Limited is a company incorporated in the
                        Republic of Botswana, with address at 3rd Floor, DTCB
                        Building, Plot No 63016, Block 8, Airport Road,
                        Gaborone, Botswana, and with registered number
                        CO2008/5689.
                      </li>
                      <p>
                        <br></br>
                        De Beers&trade;, A Diamond is Forever&trade;, DTC&trade;
                        and Forevermark&trade; are used under licence by De
                        Beers UK Limited
                      </p>
                      <p>
                        Thank you for visiting the Site.
                      </p>
                      <p>March 2021</p>
                      <p>
                        &#169; De Beers Global Sightholder Sales (Proprietary)
                        Limited {today.getFullYear()}
                      </p>
                    </ol>
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Icon
                    name="arrow left"
                    className="help-back-navigation-icon"
                  />
                  <span className="back-navigation-button-text">
                    <Link
                      to="/reservations"
                      className="back-navigation-button-text-color"
                    >
                      Back
                    </Link>
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export function GetOktaConfig() {
  const oktaConfigValue = process.env.REACT_APP_OKTA_CONFIG;
  const oktaConfig = JSON.parse(oktaConfigValue);

  oktaConfig.tokenManager = {
    expireEarlySeconds: 300, // 5 minutes early
    autoRenew: true,
  };

  oktaConfig.i18n = {
    en: {
      rememberDevice: {
        timebased: "Do not ask me for Multi Factor Authentication (MFA) again on this device for the next {0}",
        devicebased: "Do not ask me for Multi Factor Authentication (MFA) again on this device"
      }
    }
  };

  return oktaConfig;
}

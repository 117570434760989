import { isNil } from "lodash";
import { notifyError, notifySuccess } from "./notifications";

const { createAxios } = require("./axiosWrapper");

const bookingUrl = `${process.env.REACT_APP_BOOKINGS_API_URL}/api/Bookings`;

const axios = () => createAxios(bookingUrl);

export const getBookingDataAsync = async (
  customerGroupId,
  selectedItoYear,
  isPast
) => {
  try {
    const requestUrl = `get-bookings-for-customer-group?customerGroupId=${customerGroupId}&year=${selectedItoYear}`;

    const bookings = await axios().get(requestUrl);

    return bookings.data ? bookings.data : [];
  } catch (err) {
    notifyError("Failed to get the reservations.", err);
  }
};

export const getPastBookingDataAsync = async (
  customerGroupId,
  selectedItoYear,
  isPast
) => {
  try {
    const requestUrl = `get-past-bookings-for-customer-group?customerGroupId=${customerGroupId}&year=${selectedItoYear}&isPast=${isPast}`;

    const bookings = await axios().get(requestUrl);

    return bookings.data ? bookings.data : [];
  } catch (err) {
    notifyError("Failed to get the reservations.", err);
  }
};

export const getBookingAsync = async (bookingId) => {
  try {
    const requestUrl = `${bookingId}`;

    const booking = await axios().get(requestUrl);

    return booking.data ? booking.data : [];
  } catch (err) {
    notifyError("Failed to get the reservation.", err);
  }
};

export const createBooking = async (booking, setErrorMessage, setDuplicateBookingItems) => {
  try {
    const createdBooking = await axios().post("", booking, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return createdBooking.data ? createdBooking.data : [];
  } catch (err) {
    const message =
      err.response?.data?.Message || "An unexpected error occurred";

    if (message === "Cannot create a duplicate reservation.") {
      setErrorMessage(message);
        try {
          const response = await axios().post("get-duplicate-bookings", booking, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          response.data ? setDuplicateBookingItems(response.data) : setDuplicateBookingItems([]);
        } catch (duplicateErr) {
          setErrorMessage("Failed to retrieve duplicate reservations.");
        }
    } else if (err.response?.status === 400 && !isNil(message)) {
      setErrorMessage(message);
    } else {
      notifyError("Failed to create the reservation.", err);
    }
  }
};

export const updateBooking = async (booking, setErrorMessage, setDuplicateBookingItems) => {
  try {
    const updatedBooking = await axios().put("", booking, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    notifySuccess("Successfully updated the reservation.");

    return updatedBooking.data ? updatedBooking.data : [];
  } catch (err) {
      const message =
          err.response?.data?.Message || "An unexpected error occurred";

      if (message === "Cannot create duplicate booking.") {
          setErrorMessage(message);
          try {
            const response = await axios().post("get-duplicate-bookings", booking, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            response.data ? setDuplicateBookingItems(response.data) : setDuplicateBookingItems([]);
          } catch (duplicateErr) {
            setErrorMessage("Failed to retrieve duplicate bookings.");
          }
        } else if (err.response?.status === 400 && !isNil(message)) {
          setErrorMessage(message);
        } else {
          notifyError("Failed to update the reservation.", err);
      }
  }
};

export const deleteBooking = async (bookingId) => {
  try {
    const response = await axios().delete(`${bookingId}`);

    notifySuccess("Successfully cancelled the reservation.");
  } catch (err) {
    notifyError("Failed to delete the reservation.", err);
  }
};

export const getAvailableRoomsAsync = async (
  bookingWindowId,
  startDate,
  endDate
) => {
  try {
    const requestUrl = `get-available-rooms?windowId=${bookingWindowId}&startDate=${startDate}&endDate=${endDate}`;

    const bookings = await axios().get(requestUrl);

    return bookings.data ? bookings.data : [];
  } catch {
    //TODO: RETHINK THIS
    //notifyError("Failed to get the reservations.", err);
  }
};

export const getOverallBookedTimeForCustomerGroupAsync = async (
  customerGroup,
  year
) => {
  try {
    const requestUrl = `get-booked-time-for-customer-group?customerGroupId=${customerGroup}&year=${year}`;

    const bookings = await axios().get(requestUrl);

    return bookings.data ? bookings.data : [];
  } catch (err) {
    notifyError("Failed to get the reserved time for customer group.", err);
  }
};

import "./OktaLogin.scss";
import React, { useEffect, useRef, useState } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
// import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { GetOktaConfig } from "../../lib/oktaConfig";
import { useOktaAuth } from '@okta/okta-react';

const OktaLogin = () => {
  const widgetRef = useRef(null);
//   const [validatingExistingOktaSession, setValidatingExistingOktaSession] = useState(false);
  const { oktaAuth } = useOktaAuth();

  const validateExistingOktaSession = async () => {
    // setValidatingExistingOktaSession(true);
    
    oktaAuth.session.exists().then((exists) => {
      if (exists) {
        oktaAuth.token
          .getWithoutPrompt({
            responseType: ['token', 'id_token'],
          })
          .then((res) => {
            oktaAuth.handleLoginRedirect(res.tokens);

            // setValidatingExistingOktaSession(false);
          });
      } else {
        // setValidatingExistingOktaSession(false);
      }
    });
  };

  useEffect(() => {
    validateExistingOktaSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const oktaConfig = GetOktaConfig();
    
    const widget = new OktaSignIn({
      baseUrl: oktaConfig.issuer.split('/oauth2')[0],
      clientId: oktaConfig.clientId,
      redirectUri: oktaConfig.redirectUri,
      useClassicEngine: true,
      logo: "de-beers-group-logo.png",
      authParams: {
        issuer: oktaConfig.issuer,
        scopes: oktaConfig.scopes,
        pkce: oktaConfig.pkce,
        disableHttpCheck: oktaConfig.disableHttpsCheck
      },
      i18n: {
        en: {
          'rememberDevice.timebased': oktaConfig.i18n.en.rememberDevice.timebased,
          'rememberDevice.devicebased': oktaConfig.i18n.en.rememberDevice.devicebased
        }
      }
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        // Successful login
        oktaAuth.handleLoginRedirect(res.tokens).catch((err) => {
          console.error('Error in handleLoginRedirect:', err);
        });
      },
      (err) => {
        console.error('SignIn Widget Error:', err);
      }
    );

    return () => widget.remove();
  }, []);

  //TODO: Display something to the user to indicate then the login is taking place. Currently it is just showing the login screen (apearing like nothing is happening), then logs in.
  //TODO: This is especially a problem with Singnon happened on explore and the user navigates to room bookings.
  return <div ref={widgetRef} />;
};

export default OktaLogin;